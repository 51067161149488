import { pusher } from "components/PusherEvent";
import { AppReducerStateProps } from "lib/store";
import { uuidv4 } from "lib/worker/client";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get_room, { GetRoomResponse } from "lib/worker/api/get_room";
import global from "lib/config/global";

interface NotificationMessageProps {
  state: AppReducerStateProps;
}

function NotificationMessage(props: NotificationMessageProps) {
  const { isLogin } = props.state.member;
  const { Username } = props.state.member.getinfo;
  const [roomInfomation, setRoomInfomation] = useState<GetRoomResponse>();
  const [usernamer, setUsernamer] = useState<string>("");
  const { prefix } = global().gobalVariable;

  useEffect(() => {
    if (isLogin) {
      setUsernamer(Username ?? "");
    } else {
      if (localStorage.getItem("guest") == null) {
        localStorage.setItem("guest", `guest-${uuidv4()}`);
      }
      setUsernamer(localStorage.getItem("guest") ?? "");
    }
  }, []);

  useEffect(() => {
    if (usernamer == "") {
      return;
    }

    var channelReloadRoomFrontEnd = pusher.subscribe(
      "private-client-my-channel-room-front-end-" + prefix + usernamer
    );

    channelReloadRoomFrontEnd.bind(`in-room`, () => {
      reloadRoom();
    });

    reloadRoom();

    return () => {
      channelReloadRoomFrontEnd.unbind_all();
      channelReloadRoomFrontEnd.disconnect();
    };
  }, [usernamer]);

  const reloadRoom = () => {
    get_room({
      prefix: prefix,
      username: usernamer,
    })
      .then((json) => {
        setRoomInfomation(json);
      })
      .catch(() => {});
  };

  if (roomInfomation?.admin_count_un_read == 1) {
    return null;
  }

  return (
    <>
      {roomInfomation?.user_count_un_read != 0 &&
        roomInfomation != undefined && (
          <div>
            <div className="floating-noti" style={{ marginBottom: 10 }}>
              <div className="wrapper">
                <label
                  className="bg-notification"
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    marginRight: 0,
                    marginTop: -2,
                  }}
                >
                  {roomInfomation?.user_count_un_read?.format(0, 0)}
                </label>
              </div>
            </div>
          </div>
        )}{" "}
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
    bankVender: state.member.bankVender,
  };
};

export default connect(mapStateToProps)(NotificationMessage);
