import client from "../client";

export interface GetRoomRequest {
  prefix?: string;
  username?: string;
}

export interface GetRoomResponse {
  id?: boolean;
  username?: string;
  create_at?: number;
  admin_count_un_read?: number;
  user_count_un_read?: number;
  last_message?: boolean;
}

export default function API(request: GetRoomRequest): Promise<GetRoomResponse> {
  return client
    .get(`chat/get_room`, {
      params: {
        prefix: request.prefix,
        username: request.username,
      },
    })
    .then((response) => response.data);
}
